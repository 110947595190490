import { gql } from '@apollo/client';

export const GET_ORGANIZATIONS = gql`
  query ListAccountOrganizations($accountId: ID!) {
    listAccountOrganizations(accountId: $accountId) {
      id
      accountId
      name
      address
    }
  }
`;

export const GET_API_INFO = gql`
  query GetApiInfo {
    getCurrentVersion
      @rest(type: "String", path: "api-version", method: "GET") {
      version
    }
  }
`;

export const GET_ACCOUNTING_TABLE_STATE = gql`
  query GetAccountingTableState {
    accountingTableState @client {
      contacts
      currencies
      exchanges
      txDateEnd
      txDateStart
      txDateStatic
      types
      wallets
      sortColumn
      sortOrder
      contracts
    }
  }
`;

export const REQUESTED_USERS_QUERY = gql`
  query GetRequestedUsers($accountId: ID!) {
    tmpAccountUsers(accountId: $accountId) {
      email
      accountRequestedFor {
        account
        permission
      }
      token
      validTill
      inviteLink
    }
  }
`;

export const USERS_QUERY = gql`
  query GetUsers($accountId: ID!) {
    accountUsers(accountId: $accountId) {
      id
      profile {
        username
        email
      }
      active
      addAccount
      permission {
        userId
        accountId
        role
        isOwner
      }
    }
  }
`;

export const ALL_USERS_QUERY = gql`
  query GetAllUsers($accountId: ID!) {
    accountUsers(accountId: $accountId) {
      id
      profile {
        username
        email
      }
      active
      addAccount
      permission {
        userId
        accountId
        role
        isOwner
      }
    }
    tmpAccountUsers(accountId: $accountId) {
      email
      accountRequestedFor {
        account
        permission
      }
      token
      validTill
      inviteLink
    }
  }
`;

export const CB_SUBSCRIPTION = gql`
  query GetCBSubscription($accountId: ID!) {
    getChargeBeeSubscription(accountId: $accountId) {
      id
      status
      trial_end
    }
  }
`;

export const CONTACTS_QUERY = gql`
  query GetContacts($accountId: ID!) {
    accountContacts(accountId: $accountId) {
      id
      contactName
      address
      displayAddress
      glCode
    }
  }
`;

export const INTEGRATION_AUTH = gql`
  query GetIntegrationAuth($accountId: ID!, $appName: String!) {
    integrationAuth(accountId: $accountId, appName: $appName) {
      ... on XeroIntegration {
        id
        accountId
        xeroOrgName
        defaultAssetAccount
        defaultIncomeAccount
        defaultExpenseAccount
        defaultFeeAccount
        defaultSuspenseAccount
        active
        updating
        authenticationFailed
        lastSyncedWallet
        minUsdValue
        syncMode
      }
      ... on QboIntegration {
        id
        accountId
        qboOrgName
        defaultAssetAccount
        defaultIncomeAccount
        defaultExpenseAccount
        defaultFeeAccount
        defaultSuspenseAccount
        active
        updating
        authenticationFailed
        lastSyncedWallet
        minUsdValue
        syncMode
      }
    }
  }
`;

export const INTEGRATION_ACCOUNTS = gql`
  query GetIntegrationAccounts($accountId: ID!, $appName: String!) {
    integrationAccounts(accountId: $accountId, appName: $appName) {
      ... on XeroAccount {
        name
        code
        accountID
        enablePaymentsToAccount
        type
      }
      ... on QboAccount {
        Name
        Classification
        AccountType
        Id
      }
    }
  }
`;

export const EXCHANGE_MAPPINGS = gql`
  query GetExchangeMappings($accountId: ID!, $appName: String!) {
    exchangeMappings(accountId: $accountId, appName: $appName) {
      id
      accountId
      exchangeAuthId
      feeAccount
      type
      currencyMappings {
        currency
        assetAccount
        inboundAccount
        outboundAccount
      }
    }
  }
`;

export const WALLET_MAPPINGS = gql`
  query GetWalletMappings($accountId: ID!, $appName: String!) {
    walletMappings(accountId: $accountId, appName: $appName) {
      id
      walletId
      accountId
      assetAccount
      inboundAccount
      outboundAccount
      feeAccount
      cryptoMappings {
        crypto
        assetAccount
        inboundAccount
        outboundAccount
      }
    }
  }
`;

export const CHECK_INTEGRATIONS = gql`
  query CheckHasActiveIntegration($accountId: ID!) {
    checkHasActiveIntegration(accountId: $accountId)
  }
`;

export const INTEGRATION_AUTH_URL = gql`
  query GetIntegrationAuthUrl($accountId: ID!, $appName: String!) {
    integrationAuthUrl(accountId: $accountId, appName: $appName) {
      url
    }
  }
`;

export const REPORT_DATA_QUERY = gql`
  query GetWalletList($accountId: ID!) {
    wallets(accountId: $accountId) {
      id
      name
      assets {
        currency
      }
    }
    eSourceSummary(accountId: $accountId) {
      id
      name
      assets {
        currency
      }
    }
    getStablecoins(accountId: $accountId) {
      name
      symbol
      address
    }
  }
`;

export const GET_TXES = gql`
  query TableTransactions(
    $accountId: ID!
    $filter: TxQueryInput!
    $limit: Int!
  ) {
    transactionsForTable(
      accountId: $accountId
      filter: $filter
      limit: $limit
    ) {
      transactions {
        id
        txDate
        origin
        accountSource
        sourceId
        type
        currency {
          currency
          symbol
        }
        txId
        amount
        fiatValue
        contact
        externalContact
        txType
        memos {
          id
          memo
          createdByEmail
        }
        status
        isManual
        hash
        nft {
          name
          contractAddress
          tokenId
          chain
        }
      }
      totalTxes
    }
  }
`;

export const TX_DETAILS_QUERY = gql`
  query GetTxDetails(
    $accountId: ID!
    $transaction: TransactionAndSourceInput!
    $type: String!
  ) {
    transactionDetails(
      accountId: $accountId
      transaction: $transaction
      type: $type
    ) {
      id
      txDate
      origin
      inputs {
        address
        amount
        category
        currency
        symbol
        contact
        fiatValue
      }
      outputs {
        address
        amount
        category
        currency
        symbol
        contact
        fiatValue
      }
      fee
      feeCurrency
    }
  }
`;

export const FILTER_CONTACTS = gql`
  query GetContactsForFilter($accountId: ID!) {
    accountContacts(accountId: $accountId) {
      id
      contactName
    }
  }
`;

export const ACCOUNT_FILTER_CURRENCIES = gql`
  query GetCurrencies($accountId: ID!) {
    accountCurrencies(accountId: $accountId)
  }
`;

export const FILTER_WALLETS = gql`
  query GetWalletsForFilter($accountId: ID!) {
    wallets(accountId: $accountId) {
      id
      internalId
      name
      sanityIds {
        dataSourceId
        sourceId
      }
    }
  }
`;

export const FILTER_EXCHANGES = gql`
  query GetExchangesForFilter($accountId: ID!) {
    eSources(accountId: $accountId) {
      id
      nickname
      sanityIds {
        dataSourceId
        sourceId
      }
    }
  }
`;

export const FILTERS_QUERY = gql`
  query GetSavedFilters($accountId: ID!) {
    savedTableFilters(accountId: $accountId) {
      id
      accountId
      filterName
      filter {
        txDateStatic
        txDateStart
        txDateEnd
        exchanges
        wallets
        currencies
        types
        contacts
        contracts
      }
    }
  }
`;

export const EXCHANGE_IMPORT_ORDERS = gql`
  query FetchImportsForExchange($accountId: ID!, $eSourceId: ID!) {
    getImportedOrders(accountId: $accountId, eSourceId: $eSourceId) {
      id
      Timestamp
      ToCurrency
      ToAmount
      Price
      PriceCurrency
      FromCurrency
      FeeValue
      FeeCurrency
      FromAmount
      Type
      isManual
      taxCategory
      useDefaultPricing
      CustomData {
        organizationId
      }
    }
  }
`;

export const FETCH_EXCHANGES = gql`
  query FetchExchangesForNewExchange {
    eSourceList {
      name
    }
  }
`;

export const CHECK_FOR_DUPLICATE_ORDERS = gql`
  query CheckDuplicateOrderIds(
    $accountId: ID!
    $eSourceId: ID!
    $orderIds: [String]!
  ) {
    checkDuplicateOrderIds(
      accountId: $accountId
      eSourceId: $eSourceId
      orderIds: $orderIds
    ) {
      id
    }
  }
`;

export const ACCOUNT_SUBTYPES = gql`
  query GetSubtypes($tag: String!) {
    getSubtypes(tag: $tag) {
      id
      displayName
      tags
      active
    }
  }
`;

export const ACCOUNTS = gql`
  query GetAccounts($type: AccountType) {
    getAccounts(type: $type) {
      id
      name
      disabled
      owner
      type
      subtype {
        id
        displayName
      }
      permission {
        userId
        accountId
        role
        isOwner
      }
      preparerLink {
        active
        clientActive
        clientConsent
        preparerActive
      }
      subtypeFields {
        reportingPlatform
        fundId
        disclaimerAcknowledged
        portfolio
        fundStructure
        fundStructureCurrency
        streamCoreBrokerName
        sftpAdminEmail
        tirAdmin
        tirReportGenerating
      }
    }
  }
`;

export const DEFAULT_ACCOUNT_ID = gql`
  query GetDefaultAccountId($type: AccountType!) {
    getDefaultAccountId(type: $type)
  }
`;

export const ACCOUNT = gql`
  query GetAccount($accountId: ID!) {
    getAccount(accountId: $accountId) {
      accountingAdd
      accountPlan
      accountType
      active
      audit
      disabled
      fiatCurrency
      id
      inTrial
      name
      owner
      permission {
        userId
        accountId
        role
        isOwner
      }
      plan {
        id
        name
        price
        numberOfUsers
        numberOfWallets
        numberOfExchangeAccounts
        addons {
          id
          price
        }
      }
      preparerLink {
        id
        billingEntity
        clientAccountId
        clientAccountName
        clientActive
        clientEmail
        clientContactName
        clientConsent
        codeLastSent
        preparerAccountId
        preparerActive
        preparerDisplayName
        prepTypeFields {
          goSystemLocator
        }
        submittedYears
      }
      serialUpdateActive
      setupRequired
      sourceListView
      nftListView
      statistics {
        totalOnChainTransactionCount
        inactiveOnChainTransactionCount
        exchangeOrderCount
        inactiveExchangeOrderCount
      }
      subtype {
        id
        displayName
      }
      subtypeFields {
        reportingPlatform
        fundId
        disclaimerAcknowledged
        portfolio
        fundStructure
        fundStructureCurrency
        streamCoreBrokerName
        sftpAdminEmail
        tirAdmin
        tirReportGenerating
      }
      taxBilling {
        accountId
        baseTxAmount
        creditAvailable
        extraTransactions {
          year
          total
        }
        extraTransactionsAfterPay {
          year
          total
        }
        nextTxLimit {
          year
          total
        }
        currentTxLimit {
          year
          total
        }
        paymentRequired
        reportsPurchased
        taxYearReportAmount
        taxYearReportPurchased
        overagePrice {
          year
          price
          amount
          size
        }
        tierPrice {
          year
          price
          name
          lowerBound
          upperBound
        }
        history {
          tiers {
            year
            price
            name
            lowerBound
            upperBound
            timestamp
            whoPaid {
              payerType
              accountId
            }
          }
          overages {
            year
            price
            amount
            size
            timestamp
            whoPaid {
              payerType
              accountId
            }
          }
          reports {
            year
            price
            creditUsed
            timestamp
            whoPaid {
              payerType
              accountId
            }
          }
          discounts {
            year
            total
            coupon
            timestamp
            whoPaid {
              payerType
              accountId
            }
          }
          totals {
            year
            price
          }
          preparerTotals {
            year
            price
            accountId
          }
          clientTotals {
            year
            price
          }
        }
      }
      taxPayerDetails {
        id
        taxYear
        taxMethod
        hasMissingTaxData
        taxAddons {
          taxDownload2015
          taxDownload2016
          taxDownload2017
          taxDownload2018
          taxDownload2019
          taxDownload2020
          taxDownload2021
          taxDownload2022
          extraWallets
          extraExchanges
          extraTransactions
          taxTransactionsOverLimit
        }
        completedTaxYearReportCount
      }
      taxPreparerBillingDetails {
        creditsLeft
        creditsUsed
        creditAmountUsed
      }
      taxPreparerDetails {
        accountId
        firmId
        linkCode
        billingType
        type
        billing {
          creditsAmount
        }
      }
      taxUpdater {
        taxUpdateFailed
        taxUpdateQueued
        taxUpdateInProgress
        taxUpdateNeedsRerun
        algorithmMode
        specIdFeature
      }
      timezone
      trialEnded
      txTableSettings {
        showZeroValue
        showDust
        defaultTableView
      }
      type
      updating
      usage {
        numberOfUsers
        numberOfWallets
        numberOfAddresses
        numberOfTxPerFrequency
        numberOfTaxTransactions
        numberOfTaxTransactionsPerYear {
          year
          total
        }
        numberOfExchangeAccounts
        numberOfExchangeTxesPerFrequency
      }
      chainalysisIntegrationActive
      chainalysisIntegrationApiKey
      chainalysisIntegrationAuthenticationFailure
      chainalysisIntegrationEnabled
    }
  }
`;

export const ACCOUNTS_UPDATE = gql`
  query GetAccountsUpdating($type: AccountType) {
    getAccounts(type: $type) {
      id
      updating
    }
  }
`;

export const GET_CLIENT_ACCOUNTS_UPDATING = gql`
  query GetAccountsUpdating(
    $type: AccountType
    $accountIds: [ID]
    $userId: String
  ) {
    getAccounts(type: $type, accountIds: $accountIds, userId: $userId) {
      id
      updating
    }
  }
`;

export const ACCOUNT_UPDATE = gql`
  query CheckAccountUpdate($accountId: ID!) {
    getAccount(accountId: $accountId) {
      id
      updating
      serialUpdateActive
      taxUpdater {
        taxUpdateInProgress
        taxUpdateFailed
        taxUpdateNeedsRerun
        taxUpdateQueued
        specIdFeature
      }
    }
  }
`;

export const GET_LEDGIBLE_USER = gql`
  query GetLedgibleUser($accountForOwner: ID) {
    user(accountForOwner: $accountForOwner) {
      id
      profile {
        email_verified
        email
        username
        picture
        user_metadata {
          use_mfa
          referral_code
          verification_email_sent
          us_bank_portfolio_viewer
          ledgible_tmp_user_token
        }
        created_at
        last_password_reset
        updated_at
        org_id
      }
      active
      addAccount
      achEnabled
      allowedApplications
      accountPermissions {
        userId
        accountId
        role
        isOwner
        type
        tirAdmin
      }
      redirectToClient
    }
  }
`;

export const CUSTOMER_SUBSCRIPTION = gql`
  query GetSubscription($accountId: ID!) {
    getChargeBeeSubscription(accountId: $accountId) {
      id
      plan_unit_price
      plan_amount
      billing_period
      billing_period_unit
      plan_free_quantity
      status
      trial_start
      trial_end
      event_based_addons {
        id
      }
      charged_event_based_addons {
        id
        timestamp
      }
      addons {
        id
        quantity
        unit_price
        amount
      }
    }
  }
`;

export const PLANS = gql`
  query GetPlans {
    getPlans {
      id
      name
      hasTrial
      price
      billingFrequency
      billingFrequencyUnit
      numberOfUsers
      numberOfWallets
      numberOfExchangeAccounts
      addons {
        id
        price
      }
    }
  }
`;

export const PAYMENT_SOURCE_QUERY = gql`
  query GetPaymentSource($accountId: ID!) {
    getChargeBeePaymentSource(accountId: $accountId) {
      id
      deleted
      object
      customer_id
      type
      reference_id
      status
      gateway
      gateway_account_id
      card {
        first_name
        last_name
        last4
        funding_type
        expiry_month
        expiry_year
        billing_addr1
        billing_city
        billing_state_code
        billing_state
        billing_country
        billing_zip
        masked_number
        brand
      }
    }
  }
`;

export const GET_FOREX_CURRENCIES = gql`
  query GetForeignCurrencies {
    getForexCurrencies {
      fiat
    }
  }
`;

export const EXCHANGES_LIST_QUERY = gql`
  query GetExchangeList {
    eSourceList {
      name
    }
  }
`;

export const EXCHANGES_QUERY = gql`
  query GetExchanges($accountId: ID!) {
    eSources(accountId: $accountId) {
      id
      accountId
      active
      nickname
      exchangeName
      updating
      lastUpdated
      taxOwnership
      type
      authenticationFailed
      sanityIds {
        dataSourceId
        sourceId
      }
      balance {
        exchangeId
        collectedAt
        balances {
          currency
          amount
        }
      }
    }
  }
`;

export const EXCHANGE_UPDATE = gql`
  query CheckExchangeUpdate($accountId: ID!, $eSourceId: ID!) {
    eSource(accountId: $accountId, eSourceId: $eSourceId) {
      id
      updating
    }
  }
`;

export const EXCHANGES_UPDATE = gql`
  query CheckExchangesUpdating($accountId: ID!) {
    getExchangesUpdating(accountId: $accountId) {
      id
      updating
      lastUpdated
    }
  }
`;

export const EXCHANGE_QUERY = gql`
  query GetExchange($accountId: ID!, $eSourceId: ID!) {
    eSource(accountId: $accountId, eSourceId: $eSourceId) {
      id
      accountId
      active
      nickname
      usdTotal
      exchangeName
      updating
      lastUpdated
      taxOwnership
      type
      authenticationFailed
      sanityIds {
        dataSourceId
        sourceId
      }
      balance {
        id
        exchangeId
        collectedAt
        balances {
          currency
          amount
        }
      }
      balancesReconciliation {
        materialityThreshold
        assets {
          asset
          materialityThreshold
        }
      }
    }
  }
`;

export const EXCHANGE_SUMMARY_QUERY = gql`
  query GetESourceSummary($accountId: ID!, $eSourceId: ID, $date: Date) {
    eSourceSummary(accountId: $accountId, eSourceId: $eSourceId, date: $date) {
      id
      name
      usdTotal
      assets {
        currency
        amount
      }
    }
  }
`;

export const GET_EXCHANGE_BALANCES_BY_DATES = gql`
  query GetExchangeBalanceByDates(
    $accountId: ID!
    $eSourceId: ID!
    $dates: [Date]
  ) {
    getESourceBalanceByDates(
      accountId: $accountId
      eSourceId: $eSourceId
      dates: $dates
    ) {
      date
      fiatCurrency
      fiatTotal
    }
  }
`;

export const REPORT_FILTERS_OPTIONS_QUERY = gql`
  query GetReportsFilterOptions($accountId: ID!) {
    getReportsFilterOptions(accountId: $accountId) {
      statusFilterOptions
      taxYearsFilterOptions
      typeFilterOptions
    }
  }
`;

export const REPORT_TOTAL_QUERY = gql`
  query GetReportsByFilter(
    $accountId: ID!
    $filters: [PaginatedFilterInput]
    $clientAccountName: String
    $limit: Int
    $offset: Int
    $sortBy: [SortInput]
  ) {
    getReportsByFilter(
      accountId: $accountId
      clientAccountName: $clientAccountName
      filters: $filters
      limit: $limit
      offset: $offset
      sortBy: $sortBy
    ) {
      totalUnfiltered
    }
  }
`;

export const REPORTS_QUERY = gql`
  query GetReportsByFilter(
    $accountId: ID!
    $filters: [PaginatedFilterInput]
    $clientAccountName: String
    $limit: Int
    $offset: Int
    $sortBy: [SortInput]
  ) {
    getReportsByFilter(
      accountId: $accountId
      clientAccountName: $clientAccountName
      filters: $filters
      limit: $limit
      offset: $offset
      sortBy: $sortBy
    ) {
      reports {
        id
        account
        type
        taxAccountId
        taxYear
        isEmpty
        requested
        finished
        error
        status
        parameters {
          wallet
          exchange
          start
          end
          date
          asset
          filters {
            txDateStatic
          }
          selected {
            txId
          }
          taxCategory
        }
        taxDetails {
          accountName
          taxYear
          accountId
          contactName
          contactEmail
          prepTypeFields {
            goSystemLocator
          }
          preparerOnly
        }
        consumer {
          meta
        }
      }
      totalFiltered
      totalUnfiltered
    }
  }
`;

export const REPORT_STATUSES_QUERY = gql`
  query GetReportStatuses($accountId: ID!, $reports: [ReportStatusInput]) {
    getReportStatuses(accountId: $accountId, reports: $reports) {
      id
      type
      status
    }
  }
`;

export const REPORT_TYPES_QUERY = gql`
  query GetAccountReportTypes($accountId: ID!) {
    getAccountReportTypes(accountId: $accountId) {
      id
      excludeFromPagination
      extension
      clientParameters {
        hideCreate
        description
        displayName
        downloadName
      }
      defaultParameters {
        asset
        coin
        end
        date
        exchange
        filters
        fromTaxTransactionId
        limit
        organizations
        start
        timezone
        wallet
      }
    }
  }
`;

export const CHAINS_QUERY = gql`
  query GetSupportedChains {
    chainList {
      symbol
      baseChain
    }
  }
`;

export const WALLETS_QUERY = gql`
  query GetWallets($accountId: ID!) {
    wallets(accountId: $accountId) {
      id
      internalId
      account
      active
      addressCount
      name
      usdTotal
      chain
      updating
      lastUpdated
      status
      type
      xpubs
      nOfm
      taxOwnership
      authenticationFailed
      sourceUpdating
      sanityIds {
        dataSourceId
        sourceId
      }
      assets {
        currency
      }
      chainalysisWalletIntegrationActive
      chainalysisAlertsCount
    }
  }
`;

export const WALLETS_UPDATE = gql`
  query CheckWalletsUpdating($accountId: ID!) {
    getWalletsUpdating(accountId: $accountId) {
      id
      lastUpdated
      updating
      sourceUpdating
    }
  }
`;

export const WALLET_UPDATE = gql`
  query CheckWalletUpdate($accountId: ID!, $walletId: ID!) {
    wallet(accountId: $accountId, walletId: $walletId) {
      id
      updating
      sourceUpdating
    }
  }
`;

export const WALLET_QUERY = gql`
  query GetWallet($accountId: ID!, $walletId: ID!) {
    wallet(accountId: $accountId, walletId: $walletId) {
      id
      internalId
      account
      active
      name
      addressCount
      addresses {
        address
      }
      chain
      updating
      lastUpdated
      status
      type
      xpubs
      nOfm
      taxOwnership
      authenticationFailed
      sourceUpdating
      sanityIds {
        dataSourceId
        sourceId
      }
      transactions {
        TxHash
        Timestamp
        Amount
        Currency
        Type
        FiatValue
        ToContact
        FromContact
        To
        From
        TaxCategory
        CustomData {
          displayName
          type
        }
      }
      chainalysisWalletIntegrationActive
      chainalysisAlertsCount
      balancesReconciliation {
        materialityThreshold
        assets {
          asset
          contract
          materialityThreshold
        }
      }
    }
  }
`;

export const WALLET_SUMMARY_QUERY = gql`
  query GetWalletSummary($accountId: ID!, $walletId: ID!, $date: Date) {
    walletSummary(accountId: $accountId, walletId: $walletId, date: $date) {
      id
      name
      usdTotal
      assets {
        currency
        amount
      }
    }
  }
`;

export const WALLETS_SUMMARY_QUERY = gql`
  query GetWalletsSummary($accountId: ID!, $date: Date) {
    walletsSummary(accountId: $accountId, date: $date) {
      id
      name
      usdTotal
      assets {
        currency
        amount
      }
      chainalysisAlertsCount
      chainalysisWalletIntegrationActive
    }
  }
`;

export const GET_WALLET_BALANCES_BY_DATES = gql`
  query GetWalletBalanceByDates(
    $accountId: ID!
    $walletId: ID!
    $dates: [Date]
  ) {
    getWalletBalanceByDates(
      accountId: $accountId
      walletId: $walletId
      dates: $dates
    ) {
      date
      fiatCurrency
      fiatTotal
    }
  }
`;

export const WALLET_ADDRESSES_CURSOR = gql`
  query FetchAddressesForCard(
    $accountId: ID!
    $walletId: ID!
    $first: Int
    $before: String
    $last: Int
    $after: String
    $feedType: FeedType
  ) {
    wallet(accountId: $accountId, walletId: $walletId) {
      id
      addressCount
      addressFeed(
        after: $after
        first: $first
        before: $before
        last: $last
        feedType: $feedType
      ) {
        id
        edges {
          cursor
          node {
            __typename
            ... on Address {
              id
              address
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

export const WALLET_ADDRESSES = gql`
  query FetchWalletAddressesWithOffset(
    $accountId: ID!
    $walletId: ID!
    $limit: Int
    $offset: Int
  ) {
    wallet(accountId: $accountId, walletId: $walletId) {
      id
      addressCount
      addresses(limit: $limit, offset: $offset) {
        id
        address
      }
    }
  }
`;

export const GET_UNREALIZED_QUERY = gql`
  query GetUnrealizedGainLoss($accountId: ID!) {
    unrealizedGainLoss: getUnrealizedGainLoss(accountId: $accountId) {
      cmcIds
      currency
      longTerm
      shortTerm
    }
  }
`;

// limit on backend for return is 25k
export const WALLET_ADDRESSES_QUERY = gql`
  query FetchWalletAddresses($walletId: ID!, $accountId: ID!) {
    wallet(accountId: $accountId, walletId: $walletId) {
      id
      addresses {
        address
      }
    }
  }
`;

export const WALLET_IMPORT_TRANSACTIONS = gql`
  query FetchImportsForWallet($accountId: ID!, $walletId: ID!) {
    getImportedTransactions(accountId: $accountId, walletId: $walletId) {
      transactions {
        TxHash
        Timestamp
        Amount
        Currency
        Type
        FiatValue
        FiatPrice
        ToContact
        FromContact
        To
        From
        TaxCategory
        CustomData {
          asset
          chain
          displayName
          defiSource
          poolShare
          type
          contract
          organizationId
          useDefaultPricing
        }
      }
      fees {
        Fee
        FeeCurrency
        FeeCurrencyContract
      }
    }
  }
`;

export const LIQUIDITY_POOL_BALANCES = gql`
  query GetLiquidityPoolBalances(
    $accountId: ID!
    $walletId: ID!
    $address: String!
  ) {
    getLiquidityPoolsByAddress(
      accountId: $accountId
      walletId: $walletId
      address: $address
    ) {
      address
      balances {
        amount
        currency
        symbol
        fiatValue
        accruedAmount
        accruedFiatValue
      }
      poolAddress
      transactionTimestamp
      type
      totalSupply
      poolCurrencies {
        currency
        symbol
        totalSupply
      }
      providerName
      chain
    }
  }
`;

export const LIQUIDITY_POOL_TRANSACTIONS = gql`
  query GetLiquidityPoolRealizedTransactions(
    $accountId: ID!
    $walletId: ID!
    $address: String!
    $poolAddress: String!
    $toDate: String
    $fromDate: String
  ) {
    getLiquidityPoolRealizedTransactions(
      accountId: $accountId
      walletId: $walletId
      address: $address
      poolAddress: $poolAddress
      toDate: $toDate
      fromDate: $fromDate
    ) {
      fromDate
      toDate
      transactions {
        address
        poolAddress
        amount
        chain
        currency
        symbol
        type
        taxCategory
        timestamp
      }
    }
  }
`;

export const ADDRESS_SEARCH_QUERY = gql`
  query AddressSearch($accountId: ID!, $walletId: ID!, $findAddress: String!) {
    searchAddress(
      accountId: $accountId
      walletId: $walletId
      findAddress: $findAddress
    ) {
      address
    }
  }
`;

export const GET_ACCOUNT_SUMMARY = gql`
  query GetAccountSummary($accountId: ID!, $date: Date) {
    summary: accountSummary(accountId: $accountId, date: $date) {
      date
      fiatCurrency
      assets {
        cmcIds
        type
        amount
        fiatValue
        btcValue
      }
    }
  }
`;

export const SIGNUP_TOKEN_QUERY = gql`
  query CheckToken($token: String!) {
    validToken(token: $token)
      @rest(
        type: "TokenCheck"
        path: "validate-token/{args.token}"
        method: "GET"
      ) {
      tokenValid
      userEmail
    }
  }
`;

export const US_BANK_SLOTS_AVAILABLE_QUERY = gql`
  query CheckUsBankRowThreshold {
    checkUsBankSlotsAvailable
      @rest(type: "Boolean", path: "check-us-bank-users", method: "GET")
  }
`;

export const US_BANK_SIGNUP_CODE_QUERY = gql`
  query CheckSignupCode($signupCode: String!) {
    checkSignupCodeValid(signupCode: $signupCode)
      @rest(
        type: "Boolean"
        path: "check-signup-code/{args.signupCode}"
        method: "GET"
      ) {
      signupCode
      codeValidated
      userId
    }
  }
`;

export const EXCHANGE_ASSETS = gql`
  query FetchESourceAssetsForCard($eSourceId: ID!, $accountId: ID!) {
    eSource(accountId: $accountId, eSourceId: $eSourceId) {
      id
      assets {
        currency
        amount
        fiatValue
        btcValue
      }
    }
  }
`;

// Query for limited Exchange Orders List (max 100 orders, set on backend)
export const EXCHANGE_ORDERS = gql`
  query FetchOrdersForCard($accountId: ID!, $eSourceId: ID!) {
    eSource(accountId: $accountId, eSourceId: $eSourceId) {
      id
      orders {
        id
        Timestamp
        ToCurrency
        ToAmount
        FromCurrency
        PriceCurrency
        FromAmount
        Type
        isManual
        useDefaultPricing
        CustomData {
          organizationId
        }
      }
    }
  }
`;

export const WALLET_ADDRESS_COUNT = gql`
  query GetAddressCount($accountId: ID!, $walletId: ID!) {
    wallet(accountId: $accountId, walletId: $walletId) {
      id
      addressCount
    }
  }
`;

export const WALLET_ASSETS = gql`
  query FetchWalletAssetsForCard($walletId: ID!, $accountId: ID!) {
    wallet(accountId: $accountId, walletId: $walletId) {
      id
      assets {
        currency
        amount
        fiatValue
        btcValue
      }
    }
  }
`;

export const WALLET_TRANSACTIONS = gql`
  query FetchTransactionsForCard($accountId: ID!, $walletId: ID!) {
    wallet(accountId: $accountId, walletId: $walletId) {
      id
      transactions {
        TxHash
        Timestamp
        Amount
        Currency
        Type
        FiatValue
        ToContact
        FromContact
        To
        From
        TaxCategory
        CustomData {
          displayName
          type
        }
      }
    }
  }
`;

export const PENDING_LINK_QUERY = gql`
  query CheckPendingLink($email: String!) {
    checkPendingLinkCodeInvite(email: $email)
  }
`;

export const GET_TAX_TOTALS = gql`
  query GetTaxTotals($accountId: ID!) {
    getTaxTotals(accountId: $accountId) {
      allTime {
        orderCount
        transactionCount
        disposalCount
        assetCount
      }
      taxYear {
        activeExchanges
        activeWallets
        assetCount
        buyCount
        cryptoTradeCount
        depositCount
        disposalCount
        gainLossLongTerm
        gainLossShortTerm
        orderCount
        sellCount
        transactionCount
        withdrawalCount
      }
    }
  }
`;

export const TOTAL_HOLDINGS_IN_FIAT = gql`
  query TotalHoldingsInFiat($accountId: ID!) {
    totalHoldingsInFiat(accountId: $accountId) {
      offChainBalance
      onChainBalance
    }
  }
`;

export const GET_INVITE_LINKS = gql`
  query GetInviteLinks($accountId: ID!) {
    inviteLinks: getPendingPreparerInvites(accountId: $accountId) {
      id
      billingEntity
      clientContactName
      codeLastSent
      preparerDisplayName
      linkCreatorEmail
      preparerDetails {
        linkCode
      }
    }
  }
`;

export const GET_TAX_TRANSACTIONS = gql`
  query GetTaxTransactions(
    $accountId: ID!
    $filter: TxTableForTaxFilter!
    $limit: Int!
    $cursor: String!
    $feedType: FeedType!
    $paginationDirection: PaginationDirection
  ) {
    getPaginatedTxesForTax(
      accountId: $accountId
      filter: $filter
      limit: $limit
      cursor: $cursor
      feedType: $feedType
      paginationDirection: $paginationDirection
    ) {
      edges {
        cursor
        node {
          ... on TaxTx {
            id
            transactionId
            timestamp
            costBasis
            costBasisDisregardingFees
            customData {
              organizationId
            }
            txHash
            sourceName
            sourceType
            sourceBase
            sourceId
            type
            currency
            category
            amount
            proceeds
            ignore
            gainLoss
            isEdited
            isManual
            txSide
            assetLogo
            memos {
              id
              transactionId
              memo
              createdBy
              createdByEmail
              createdAt
            }
            matchRef {
              amount
              sourceId
              sourceName
              sourceType
              timestamp
              transactionId
              id
            }
            priceAtTimeOfTx
            transferMatchingDisabled
            transferMatchManual
            reconciliations {
              amount
              costBasis
              costBasisDisregardingFees
              proceeds
              proceedsDisregardingFees
              originalAmount
              priceAtTimeOfTx
              sourceId
              sourceBase
              sourceName
              sourceType
              timestamp
              transactionId
              txHash
              isEdited
              isManual
              isSpecId
            }
            userControl {
              category
              transferMatching
            }
            userChanges {
              category
            }
            context {
              assetName
              chain
              collectionName
              contractAddress
              tokenId
              subtype
            }
            costBasisAssignments {
              lotIds
              lotNo
            }
            specIdTransactions {
              assignmentPercentage
              assignmentQuantity
              assignmentValue
              priceAtTimeOfTx
              sourceBase
              sourceId
              sourceName
              sourceType
              specId
              timestamp
              transactionId
              txHash
              txSide
            }
          }
        }
      }
      pageInfo {
        offset
        hasNextPage
        hasPreviousPage
      }
      meta {
        ... on TxTableTaxMeta {
          needsReviewCount
          sortColumn
          sortOrder
          hasAny
          count
        }
      }
    }
  }
`;

export const GET_MATCHABLE_TAX_TXES = gql`
  query GetMatchableTaxTxes($accountId: ID!, $taxTxId: String!) {
    getMatchableTaxTxes(accountId: $accountId, taxTxId: $taxTxId) {
      id
      transactionId
      timestamp
      costBasis
      costBasisDisregardingFees
      txHash
      sourceName
      sourceType
      sourceBase
      sourceId
      type
      currency
      category
      amount
      proceeds
      ignore
      gainLoss
      isEdited
      isManual
      txSide
      memos {
        id
        memo
        createdByEmail
      }
      matchRef {
        amount
        sourceId
        sourceName
        sourceType
        timestamp
        transactionId
      }
      priceAtTimeOfTx
      transferMatchingDisabled
      transferMatchManual
      reconciliations {
        amount
        costBasis
        costBasisDisregardingFees
        proceeds
        proceedsDisregardingFees
        originalAmount
        priceAtTimeOfTx
        sourceId
        sourceBase
        sourceName
        sourceType
        timestamp
        transactionId
        txHash
        isEdited
        isManual
        isSpecId
      }
      costBasisAssignments {
        lotIds
        lotNo
      }
      specIdTransactions {
        assignmentPercentage
        assignmentQuantity
        assignmentValue
        priceAtTimeOfTx
        sourceBase
        sourceId
        sourceName
        sourceType
        specId
        timestamp
        transactionId
        txHash
        txSide
      }
    }
  }
`;

export const GET_COST_BASIS_ASSIGNMENTS_FOR_TX = gql`
  query GetCostBasisAssignmentsForTx($accountId: ID!, $taxTxId: String!) {
    getCostBasisAssignmentsForTx(accountId: $accountId, taxTxId: $taxTxId) {
      id
      transactionId
      timestamp
      costBasis
      costBasisDisregardingFees
      txHash
      sourceName
      sourceType
      sourceId
      type
      currency
      amount
      gainLoss
      proceeds
      txSide
      priceAtTimeOfTx
      assetLogo
      costBasisAssignments {
        lotIds
        lotNo
      }
    }
  }
`;

export const TAX_FILTER_CURRENCIES = gql`
  query GetTaxCurrencies($accountId: ID!) {
    getTaxAccountCurrencies(accountId: $accountId)
  }
`;

export const GET_PREPARER_LINKS = gql`
  query GetPreparerLinksByFilter(
    $accountId: ID!
    $filters: [PaginatedFilterInput]
    $clientAccountName: String
    $limit: Int
    $offset: Int
    $sortBy: [SortInput]
  ) {
    getPreparerLinksByFilter(
      accountId: $accountId
      clientAccountName: $clientAccountName
      filters: $filters
      limit: $limit
      offset: $offset
      sortBy: $sortBy
    ) {
      totalFiltered
      totalUnfiltered
      preparerLinks {
        id
        active
        billingEntity
        clientAccountId
        clientAccountName
        clientActive
        clientEmail
        clientContactName
        clientConsent
        clientStatus
        codeLastSent
        createdAt
        emailInvite {
          email
          token
          validTill
          lastSent
          inviteLink
        }
        linkCreatorEmail
        preparerAccountId
        preparerActive
        preparerDisplayName
        preparerOnly
        accountLinkStatus
        prepTypeFields {
          goSystemLocator
        }
        submittedYears
        clientTaxDetail {
          id
          taxYear
          taxMethod
          hasMissingTaxData
        }
        clientTaxYearReportCount
        preparerCharges {
          year
          amount
          billedOverages
          overages
          creditApplied
        }
        preparerDetails {
          type
        }
        clientAccount {
          timezone
          updating
          serialUpdateActive
          usage {
            numberOfExchangeAccounts
            numberOfWallets
          }
          taxUpdater {
            taxUpdateFailed
            taxUpdateQueued
            taxUpdateInProgress
            taxUpdateNeedsRerun
            algorithmMode
          }
        }
        taxBilling {
          accountId
          baseTxAmount
          creditAvailable
          extraTransactions {
            year
            total
          }
          extraTransactionsAfterPay {
            year
            total
          }
          nextTxLimit {
            year
            total
          }
          currentTxLimit {
            year
            total
          }
          paymentRequired
          reportsPurchased
          taxYearReportAmount
          taxYearReportPurchased
          overagePrice {
            year
            price
            amount
            size
          }
          tierPrice {
            year
            price
            name
            lowerBound
            upperBound
          }
          history {
            tiers {
              year
              price
              name
              lowerBound
              upperBound
              timestamp
              whoPaid {
                payerType
                accountId
              }
            }
            overages {
              year
              price
              amount
              size
              timestamp
              whoPaid {
                payerType
                accountId
              }
            }
            reports {
              year
              price
              creditUsed
              timestamp
              whoPaid {
                payerType
                accountId
              }
            }
            discounts {
              year
              total
              coupon
              timestamp
              whoPaid {
                payerType
                accountId
              }
            }
            totals {
              year
              price
            }
            preparerTotals {
              year
              price
              accountId
            }
            clientTotals {
              year
              price
            }
          }
        }
        usage {
          numberOfUsers
          numberOfWallets
          numberOfAddresses
          numberOfTxPerFrequency
          numberOfTaxTransactions
          numberOfTaxTransactionsPerYear {
            year
            total
          }
          numberOfExchangeAccounts
          numberOfExchangeTxesPerFrequency
        }
        statuses
      }
    }
  }
`;

export const GET_PREPARER_LINKS_FILTER_OPTIONS = gql`
  query GetPreparerLinksFilterOptions($accountId: ID!) {
    getPreparerLinksFilterOptions(accountId: $accountId) {
      statusFilterOptions
      taxYearsFilterOptions
      linkStatusFilterOptions
    }
  }
`;

export const GET_PREPARER_LINK = gql`
  query GetPreparerLink($accountId: ID!, $preparerLinkId: String!) {
    getPreparerLink(accountId: $accountId, preparerLinkId: $preparerLinkId) {
      id
      active
      billingEntity
      clientAccountId
      clientAccountName
      clientActive
      clientEmail
      clientContactName
      clientConsent
      clientStatus
      codeLastSent
      createdAt
      emailInvite {
        email
        token
        validTill
        lastSent
        inviteLink
      }
      linkCreatorEmail
      preparerAccountId
      preparerActive
      preparerDisplayName
      preparerOnly
      prepTypeFields {
        goSystemLocator
      }
      submittedYears
      clientTaxDetail {
        id
        taxYear
        taxMethod
        hasMissingTaxData
        taxAddons {
          taxDownload2015
          taxDownload2016
          taxDownload2017
          taxDownload2018
          taxDownload2019
          taxDownload2020
          taxDownload2021
          taxDownload2022
          taxDownload2023
          taxDownload2024
          taxDownload2025
          taxDownload2026
          taxDownload2027
          taxDownload2028
          taxDownload2029
          taxDownload2030
          extraWallets
          extraExchanges
        }
      }
      clientTaxYearReportCount
      preparerCharges {
        year
        amount
        billedOverages
        overages
        creditApplied
      }
      preparerDetails {
        type
      }
      clientAccount {
        timezone
        updating
        serialUpdateActive
        usage {
          numberOfWallets
          numberOfExchangeAccounts
        }
        taxUpdater {
          taxUpdateFailed
          taxUpdateQueued
          taxUpdateInProgress
          taxUpdateNeedsRerun
          algorithmMode
          specIdFeature
        }
      }
      taxBilling {
        accountId
        baseTxAmount
        creditAvailable
        extraTransactions {
          year
          total
        }
        extraTransactionsAfterPay {
          year
          total
        }
        nextTxLimit {
          year
          total
        }
        currentTxLimit {
          year
          total
        }
        paymentRequired
        reportsPurchased
        taxYearReportAmount
        taxYearReportPurchased
        overagePrice {
          year
          price
          amount
          size
        }
        tierPrice {
          year
          price
          name
          lowerBound
          upperBound
        }
        history {
          tiers {
            year
            price
            name
            lowerBound
            upperBound
            timestamp
            whoPaid {
              payerType
              accountId
            }
          }
          overages {
            year
            price
            amount
            size
            timestamp
            whoPaid {
              payerType
              accountId
            }
          }
          reports {
            year
            price
            creditUsed
            timestamp
            whoPaid {
              payerType
              accountId
            }
          }
          discounts {
            year
            total
            coupon
            timestamp
            whoPaid {
              payerType
              accountId
            }
          }
          totals {
            year
            price
          }
          preparerTotals {
            year
            price
            accountId
          }
          clientTotals {
            year
            price
          }
        }
      }
      usage {
        numberOfUsers
        numberOfWallets
        numberOfAddresses
        numberOfTxPerFrequency
        numberOfTaxTransactions
        numberOfTaxTransactionsPerYear {
          year
          total
        }
        numberOfExchangeAccounts
        numberOfExchangeTxesPerFrequency
      }
      status
    }
  }
`;

export const GET_PREPARER_LINK_STATUS_TOTALS = gql`
  query GetPreparerLinkStatusTotals($accountId: ID!) {
    getPreparerLinkStatusTotals(accountId: $accountId) {
      totalInProgress
      totalSubmitted
      totalReportGenerated
    }
  }
`;

export const GET_TAXPAYER_BILLING = gql`
  query GetTaxPayerBilling($accountId: ID!) {
    getAccount(accountId: $accountId) {
      id
      usage {
        numberOfTaxTransactionsPerYear {
          year
          total
        }
      }
      taxBilling {
        accountId
        baseTxAmount
        creditAvailable
        extraTransactions {
          year
          total
        }
        extraTransactionsAfterPay {
          year
          total
        }
        nextTxLimit {
          year
          total
        }
        currentTxLimit {
          year
          total
        }
        paymentRequired
        reportsPurchased
        taxYearReportAmount
        taxYearReportPurchased
        overagePrice {
          year
          price
          amount
          size
        }
        tierPrice {
          year
          price
          name
          lowerBound
          upperBound
        }
        history {
          tiers {
            year
            price
            name
            lowerBound
            upperBound
            timestamp
            whoPaid {
              payerType
              accountId
            }
          }
          overages {
            year
            price
            amount
            size
            timestamp
            whoPaid {
              payerType
              accountId
            }
          }
          reports {
            year
            price
            creditUsed
            timestamp
            whoPaid {
              payerType
              accountId
            }
          }
          discounts {
            year
            total
            coupon
            timestamp
            whoPaid {
              payerType
              accountId
            }
          }
          totals {
            year
            price
          }
          preparerTotals {
            year
            price
            accountId
          }
          clientTotals {
            year
            price
          }
        }
      }
    }
  }
`;

export const CREATOR_USER_QUERY = gql`
  query GetOwnerUser($accountForOwner: ID) {
    user(accountForOwner: $accountForOwner) {
      id
      profile {
        email
      }
    }
  }
`;

export const CHECK_CLIENT_ACCESS = gql`
  query CheckClientAccess($accountId: ID!, $preparerLinkId: ID!) {
    checkClientAccessPermitted(
      accountId: $accountId
      preparerLinkId: $preparerLinkId
    )
  }
`;

export const CHECK_TAX_USER_EMAIL = gql`
  query CheckTaxUserEmail($email: String!, $accountId: ID!) {
    checkTaxClientEmail(email: $email, accountId: $accountId) {
      existingUser
      existingInvite
      existingClient
    }
  }
`;

export const ALL_PREPARER_USERS_QUERY = gql`
  query GetPreparerUsers($accountId: ID!) {
    accountUsers(accountId: $accountId) {
      id
      profile {
        email
      }
      active
      addAccount
      permission {
        userId
        accountId
        role
        isOwner
      }
    }
    tmpAccountUsers(accountId: $accountId) {
      email
      accountRequestedFor {
        account
        permission
      }
      token
      validTill
      lastSent
      inviteLink
    }
  }
`;

export const TEAM_MEMBER_PERMISSIONS = gql`
  query GetPermittedAccounts($preparerId: ID!, $accountId: ID!) {
    taxPreparerPermissions(preparerId: $preparerId, accountId: $accountId) {
      userId
      accountId
      role
    }
  }
`;

export const TAX_TX_FILTERS = gql`
  query getTaxTxFilters($accountId: ID!) {
    getTaxTxFilters(accountId: $accountId) {
      id
      accountId
      filter {
        currency
        addressFrom
        addressTo
        startTime
        endTime
      }
      lastRan
      mode
      category
    }
  }
`;

export const GET_REALIZED_GAIN_LOSS_PER_YEAR = gql`
  query GetGainLossPerYear($accountId: ID!) {
    getGainLossPerYear(accountId: $accountId) {
      gainLossShortTerm
      gainLossLongTerm
      year
    }
  }
`;

export const GET_INCOME_BREAKDOWN_PER_YEAR = gql`
  query GetIncomeBreakdownPerYear($accountId: ID!) {
    getIncomeBreakdown(accountId: $accountId) {
      category
      current
      total
    }
  }
`;

export const GET_TRANSACTION_COMPATIBLE_CATEGORIES = gql`
  query GetTransactionCompatibleCategories(
    $accountId: ID!
    $appLocation: CategoryAssignmentAppLocation
    $taxTxesIds: [ID!]
  ) {
    getTransactionCompatibleCategories(
      accountId: $accountId
      appLocation: $appLocation
      taxTxesIds: $taxTxesIds
    ) {
      defi
      nft
      standard
    }
  }
`;

export const GET_GROUPED_TAX_CATEGORIES = gql`
  query GetTaxCategoriesGrouped {
    getTaxCategoriesGrouped {
      defi {
        canCreateManualEntry
        dbValue
        title
        system
        txSides
        txSourceTypes
        taxTreatment
      }
      nft {
        canCreateManualEntry
        dbValue
        title
        system
        txSides
        txSourceTypes
        taxTreatment
      }
      standard {
        canCreateManualEntry
        dbValue
        title
        system
        txSides
        txSourceTypes
        taxTreatment
      }
    }
  }
`;

export const GET_BLOCKCHAIN = gql`
  query GetBlockchain($id: ID!) {
    Blockchain(id: $id) {
      _id
      blockExplorerLinks {
        blockExplorerName
        addressUrl
        transactionUrl
        xpubUrl
      }
      defiSupportEnabled
      hasNativeSupport
      hdSupportEnabled
      hasSmartContracts
      instructionsForFileRaw
      name
      nftSupportEnabled
      symbol
      title
      _type
      wallets {
        name
        title
        hasWalletConnection
        isBrowserExtensionWallet
        isHardwareWallet
        walletConnectExplorerId
        aliasFor {
          name
        }
      }
    }
  }
`;

export const GET_ALL_BLOCKCHAINS = gql`
  query GetAllBlockchains($where: BlockchainFilter) {
    allBlockchain(where: $where) {
      blockExplorerLinks {
        blockExplorerName
        addressUrl
        transactionUrl
        xpubUrl
      }
      defiSupportEnabled
      hasNativeSupport
      hdSupportEnabled
      hasSmartContracts
      instructionsForFileRaw
      name
      nftSupportEnabled
      symbol
      title
      _id
      _type
      wallets {
        name
        title
        hasWalletConnection
        isBrowserExtensionWallet
        isHardwareWallet
        walletConnectExplorerId
        aliasFor {
          name
        }
      }
    }
  }
`;

export const GET_EXCHANGE = gql`
  query GetExchange($id: ID!) {
    Exchange(id: $id) {
      hasApi
      hasCustomFile
      hasOAuth
      instructionsForApiRaw
      instructionsForFileRaw
      instructionsForOAuthRaw
      name
      requiredCredentials {
        login
        loginDisplayName
        uid
        uidDisplayName
        password
        passwordDisplayName
        apiKey
        apiKeyDisplayName
        secret
        secretDisplayName
      }
      sourceUrl
      title
      _id
      _type
    }
  }
`;

export const GET_ALL_EXCHANGES = gql`
  query GetAllExchanges($where: ExchangeFilter) {
    allExchange(where: $where) {
      hasApi
      hasCustomFile
      hasOAuth
      instructionsForApiRaw
      instructionsForFileRaw
      instructionsForOAuthRaw
      name
      requiredCredentials {
        login
        loginDisplayName
        uid
        uidDisplayName
        password
        passwordDisplayName
        apiKey
        apiKeyDisplayName
        secret
        secretDisplayName
      }
      sourceUrl
      title
      _id
      _type
    }
  }
`;

export const GET_EXCHANGE_TITLES = gql`
  query GetExchangeTitles($where: ExchangeFilter) {
    allExchange(where: $where) {
      name
      title
      _id
    }
  }
`;

export const GET_CUSTODIAN = gql`
  query GetCustodian($id: ID!) {
    Custodian(id: $id) {
      hasApi
      hasOAuth
      hasCustomFile
      instructionsForApiRaw
      instructionsForFileRaw
      instructionsForOAuthRaw
      name
      requiredCredentials {
        username
        usernameDisplayName
        password
        passwordDisplayName
        apiKey
        apiKeyDisplayName
        apiSecret
        apiSecretDisplayName
        url
        urlDisplayName
      }
      sourceUrl
      title
      _id
      _type
    }
  }
`;

export const GET_ALL_CUSTODIANS = gql`
  query GetAllCustodians($where: CustodianFilter) {
    allCustodian(where: $where) {
      hasApi
      hasOAuth
      hasCustomFile
      instructionsForApiRaw
      instructionsForFileRaw
      instructionsForOAuthRaw
      name
      requiredCredentials {
        username
        usernameDisplayName
        password
        passwordDisplayName
        apiKey
        apiKeyDisplayName
        apiSecret
        apiSecretDisplayName
        url
        urlDisplayName
      }
      sourceUrl
      title
      _id
      _type
    }
  }
`;

export const GET_WALLET = gql`
  query GetWallet($id: ID!) {
    Wallet(id: $id) {
      aliasFor {
        name
      }
      blockchains {
        name
        symbol
        title
        hasNativeSupport
        hdSupportEnabled
      }
      hasCustomFile
      hasWalletConnection
      name
      instructionsForFileRaw
      instructionsForOAuthRaw
      isBrowserExtensionWallet
      isHardwareWallet
      sourceUrl
      title
      walletConnectExplorerId
      _id
      _type
    }
  }
`;

export const GET_DATA_SOURCE = gql`
  query getDataSource($id: ID!) {
    DataSource(id: $id) {
      _id
      name
      title
      supportedTypes {
        label
        value
      }
    }
  }
`;

export const GET_DATA_SOURCE_ICON = gql`
  query getDataSource($id: ID!) {
    DataSource(id: $id) {
      _id
      name
      title
      iconLogo {
        asset {
          url
        }
      }
      typeReferences {
        __typename
      }
    }
  }
`;

export const GET_ALL_DATA_SOURCES = gql`
  query getAllDataSources($where: DataSourceFilter) {
    allDataSource(where: $where) {
      _id
      name
      title
      tags {
        value
        label
      }
      isSourceEnabled
      auditUseEnabled
      iconLogo {
        asset {
          url
        }
      }
      fullLogo {
        asset {
          url
        }
      }
      supportedTypes {
        value
        label
      }
      typeReferences {
        ... on Blockchain {
          _id
        }
        ... on Custodian {
          _id
        }
        ... on Exchange {
          _id
        }
        ... on Wallet {
          _id
        }
        __typename
      }
    }
  }
`;

export const GET_ALL_DATA_SOURCE_TYPES = gql`
  query getDataSource($where: DataSourceFilter) {
    allDataSource(where: $where) {
      _id
      name
      title
      supportedTypes {
        label
        value
      }
    }
  }
`;

export const GET_DATA_SOURCE_ICONS = gql`
  query getAllDataSources($where: DataSourceFilter) {
    allDataSource(where: $where) {
      _id
      name
      title
      iconLogo {
        asset {
          url
        }
      }
      supportedTypes {
        value
      }
      typeReferences {
        ... on Exchange {
          _id
          hasApi
          hasOAuth
          hasCustomFile
        }
        __typename
      }
    }
  }
`;

export const LOGS_FILTER_OPTIONS = gql`
  query GetLogsFilterOptions($accountId: ID!) {
    getLogsFilterOptions(accountId: $accountId) {
      userFilterOptions {
        userId
        username
      }
      typeFilterOptions
      actionFilterOptions
    }
  }
`;

export const GET_LOGS_BY_FILTER = gql`
  query GetLogsByFilter(
    $accountId: ID!, 
    $filters: LogsFilterInput, 
    $offset: Int!, 
    $limit: Int!
  ) {
    getLogsByFilter(
      accountId: $accountId,
      filters: $filters, 
      offset: $offset, 
      limit: $limit
    ) {
      logs {
        id
        accountId
        timestamp
        message
        type
        itemId
        username
        email
        action
      }
      total
    }
  }
`;

export const GET_CURRENCIES = gql`
  query GetCurrencies($accountId: ID!, $filter: CurrencyFilter!) {
    getCurrencies(accountId: $accountId, filter: $filter) {
      id
      asset
      assetName
      cmcIds
      displayName
      contract
      providerCurrency
    }
  }
`;

export const GET_NFTS_BY_WALLET = gql`
  query GetNftsByWallet(
    $accountId: ID!
    $walletId: ID!
    $includePaymentInfo: Boolean
  ) {
    getNftsByWallet(
      accountId: $accountId
      walletId: $walletId
      includePaymentInfo: $includePaymentInfo
    ) {
      name
      count
      ownedBy
    }
  }
`;

export const GET_NFT_DETAILS = gql`
  query GetNftDetails($accountId: ID!, $walletId: ID!, $id: String!) {
    getNftDetails(accountId: $accountId, walletId: $walletId, id: $id) {
      name
      collectionName
      imageUrl
      tokenId
      tokenType
      contractAddress
      count
      costBasisDetail {
        costBasisAverage
        costBasisTotal
        unrealizedGainLossAverage
        unrealizedGainLossTotal
        breakdown {
          acquisitionCostPerUnit
          acquisitionDate
          amount
          unrealizedGainLossPerUnit
        }
      }
      sourceInfo
    }
  }
`;

export const GET_OWNED_NFT_DETAILS = gql`
  query GetOwnedNftDetails(
    $accountId: ID!
    $tokenId: String!
    $contractAddress: String!
    $walletId: ID!
    $includePaymentInfo: Boolean
  ) {
    getOwnedNftDetails(
      accountId: $accountId
      tokenId: $tokenId
      contract: $contractAddress
      walletId: $walletId
      includePaymentInfo: $includePaymentInfo
    ) {
      name
      collectionName
      imageUrl
      tokenId
      tokenType
      contractAddress
      count
      costBasisDetail {
        costBasisAverage
        costBasisTotal
        unrealizedGainLossAverage
        unrealizedGainLossTotal
        breakdown {
          acquisitionCostPerUnit
          acquisitionDate
          amount
          unrealizedGainLossPerUnit
        }
      }
      sourceInfo
    }
  }
`;

export const GET_NFT_COLLECTIONS = gql`
  query GetTaxAccountNftCollections($accountId: ID!) {
    getTaxAccountNftCollections(accountId: $accountId) {
      name
      contractAddress
    }
  }
`;

export const FAILED_MANUAL_EXCHANGE_ORDERS = gql`
  query GetFailedManualExchangeOrders($accountId: ID!, $eSourceId: ID!) {
    getFailedManualExchangeOrders(
      accountId: $accountId
      eSourceId: $eSourceId
    ) {
      amount
      date
      fee
      feeCurrency
      fromAmount
      fromSymbol
      id
      price
      priceCurrency
      raw_data
      source
      symbol
      taxCategory
      toAmount
      toSymbol
      type
      organizationId
      fileId
      messages
    }
  }
`;

export const FAILED_MANUAL_WALLET_TRANSACTIONS = gql`
  query GetFailedManualWalletTransactions($accountId: ID!, $walletId: ID!) {
    getFailedManualWalletTransactions(
      accountId: $accountId
      walletId: $walletId
    ) {
      amount
      amount1
      amount2
      amount3
      amount4
      asset
      asset1
      asset2
      asset3
      asset4
      chain
      contract
      date
      displayName
      defiSource
      fee
      feeCurrency
      id
      organizationId
      price
      priceCurrency
      poolShare
      symbol
      taxCategory
      type
      uniqueId
      type
      fileId
      messages
    }
  }
`;

export const GET_USER_OAUTH_CODE = gql`
  query GetUserOAuthCode($clientId: String!) {
    getUserOAuthCode(clientId: $clientId) {
      authorizationCode
      redirectUri
      state
    }
  }
`;

export const GET_NFT_COLLECTIONS_BY_ACCOUNT = gql`
  query GetNftCollectionsByAccount($accountId: ID!) {
    getNftCollectionsByAccount(accountId: $accountId) {
      name
      contractAddress
    }
  }
`;

export const GET_NFT_COLLECTION_SUMMARY_BY_ACCOUNT = gql`
  query GetNFTCollectionSummaryByAccount($accountId: ID!, $limit: Int) {
    getNFTCollectionSummaryByAccount(accountId: $accountId, limit: $limit) {
      collections {
        acquisitionCost
        assetCount
        uniqueCount
        collectionName
        contractAddress
        floorPrice
        realizedGainLoss
        unrealizedGainLoss
        walletId
      }
      fiatCurrency
      totalTypeCount
    }
  }
`;

export const GET_NFTS_BY_SEARCH = gql`
  query GetNftsBySearch(
    $accountId: ID!
    $limit: Int
    $after: String
    $sortBy: NFTSuiteSortBy
    $feedType: FeedType
    $includePaymentInfo: Boolean
    $nftFilter: NftSuiteFilter
  ) {
    getNFTsByNameOrCollection(
      accountId: $accountId
      limit: $limit
      after: $after
      sortBy: $sortBy
      feedType: $feedType
      includePaymentInfo: $includePaymentInfo
      nftFilter: $nftFilter
    ) {
      id
      edges {
        cursor
        node {
          __typename
          ... on NFT {
            acquisitionCurrency
            collectionName
            contractAddress
            costBasisDetail {
              costBasisAverage
              costBasisTotal
              unrealizedGainLossAverage
              unrealizedGainLossTotal
              breakdown {
                acquisitionCostPerUnit
                acquisitionDate
                amount
                unrealizedGainLossPerUnit
              }
            }
            imageUrl
            name
            walletId
            tokenId
            tokenType
            chain
            count
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalAssetCount
      totalTypeCount
    }
  }
`;

export const GET_NFT_DETAILS_FOR_SUITE = gql`
  query GetNftDetailsForSuite(
    $accountId: ID!
    $tokenId: String!
    $contract: String!
    $walletId: ID!
    $includePaymentInfo: Boolean
  ) {
    getOwnedNftDetails(
      accountId: $accountId
      tokenId: $tokenId
      contract: $contract
      walletId: $walletId
      includePaymentInfo: $includePaymentInfo
    ) {
      attributes {
        traitType
        value
      }
      rarity {
        model
        score
        rank
      }
      acquisitionCurrency
      collectionName
      contractAddress
      imageUrl
      name
      walletId
      tokenId
      chain
      isBurned
      mintDate
      sourceInfo
      sourceType
      tokenType
    }
  }
`;

export const GET_KYT_CHAINALYSIS_ALERTS = gql`
  query GetKYTAlerts($accountId: ID!) {
    getKytAlerts(accountId: $accountId) {
      alertStatus
      rule
      createdAt
      valid
      alertAmountUsd
      exposureType
      alertIdentifier
      transferReference
      direction
      timestamp
      transferredValuePercentage
      alertType
      level
      transferOutputAddress
      service
      validity
      category
      asset
      categoryId
      transactionHash
      walletId
      walletName
    }
  }
`;

export const GET_TIR_CORRECTION_FILES = gql`
  query GetTirCorrectionFiles($accountId: ID!) {
    getTirCorrectionFiles(accountId: $accountId) {
      id
      accountId
      filename
      uploadedAt
    }
  }
`;

export const GET_GCP_FILES = gql`
  query GetGcpFiles($accountId: ID!) {
    getGcpFiles(accountId: $accountId) {
      id
      filename
      taxYear
      status
      createdAt
    }
  }
`;

export const GET_TIR_STATISTICS = gql`
  query GetTirStatistics(
    $accountId: ID!
    $taxYear: Int!
    $specificity: String
  ) {
    getTirStatistics(
      accountId: $accountId
      taxYear: $taxYear
      specificity: $specificity
    ) {
      name
      total
    }
  }
`;

export const GET_TIR_1099_CATEGORIES = gql`
  query GetTir1099Categories(
    $accountId: ID!
    $taxYear: Int!
    $specificity: String
  ) {
    getTir1099Categories(
      accountId: $accountId
      taxYear: $taxYear
      specificity: $specificity
    ) {
      name
      total
    }
  }
`;

export const GET_EXCHANGE_AUDIT_BALANCES = gql`
  query GetAuditExchangeBalances($accountId: ID!, $eSourceId: ID!) {
    getExchangeBalancesAudit(accountId: $accountId, eSourceId: $eSourceId) {
      asset
      quantity
      quantityVariance
      reconciliationQuantity
      reconciliationValue
      timestamp
      value
      valueVariance
    }
  }
`;

export const GET_EXCHANGE_ASSET_AUDIT_BALANCES = gql`
  query GetAssetAuditExchangeBalances(
    $accountId: ID!
    $eSourceId: ID!
    $asset: String!
  ) {
    getExchangeAssetBalancesAudit(
      accountId: $accountId
      eSourceId: $eSourceId
      asset: $asset
    ) {
      asset
      quantity
      quantityVariance
      reconciliationQuantity
      reconciliationValue
      timestamp
      value
      valueVariance
    }
  }
`;

export const GET_WALLET_AUDIT_BALANCES = gql`
  query GetAuditWalletBalances($accountId: ID!, $walletId: ID!) {
    getWalletBalancesAudit(accountId: $accountId, walletId: $walletId) {
      asset
      quantity
      quantityVariance
      reconciliationQuantity
      reconciliationValue
      timestamp
      value
      valueVariance
    }
  }
`;

export const GET_WALLET_ASSET_AUDIT_BALANCES = gql`
  query GetAssetAuditWalletBalances(
    $accountId: ID!
    $walletId: ID!
    $contract: String!
  ) {
    getWalletAssetBalancesAudit(
      accountId: $accountId
      walletId: $walletId
      contract: $contract
    ) {
      asset
      contract
      quantity
      quantityVariance
      reconciliationQuantity
      reconciliationValue
      timestamp
      value
      valueVariance
    }
  }
`;

export const GET_ACCOUNT_AUDIT_BALANCE = gql`
  query GetAccountAuditBalance($accountId: ID!) {
    getAccountBalanceAudit(accountId: $accountId) {
      value
      reconciliationValue
      thresholdValue
      thresholdReconciliationValue
    }
  }
`;

export const GET_MFA_CODE = gql`
  query GetMFACode($accountId: ID!) {
    getMFACode(accountId: $accountId) {
      code
    }
  }
`;
